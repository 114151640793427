var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        'departments',
        {'departments--open': _vm.isOpen},
        {'departments--transition': _vm.isTransition}
    ]},[_c('div',{ref:"body",staticClass:"departments__body"},[_c('div',{ref:"content",staticClass:"departments__links-wrapper",on:{"mouseleave":_vm.onMouseLeave}},[_c('div',{ref:"container",staticClass:"departments__submenus-container"},[_vm._l((_vm.items),function(department,index){return [(department.submenu && department.submenu.type === 'megamenu')?_c('div',{key:index,ref:"submenus",refInFor:true,class:[
                            'departments__submenu',
                            'departments__submenu--type--megamenu',
                            `departments__submenu--size--${department.submenu.menu.size}`
                        ]},[_c('Megamenu',{attrs:{"location":"departments","menu":department.submenu.menu}})],1):_vm._e(),_vm._v(" "),(department.submenu && department.submenu.type === 'menu')?_c('div',{key:index,ref:"submenus",refInFor:true,class:[
                            'departments__submenu',
                            'departments__submenu--type--menu'
                        ]},[_c('Menu',{attrs:{"items":department.submenu.menu}})],1):_vm._e()]})],2),_vm._v(" "),_c('ul',{staticClass:"departments__links"},_vm._l((_vm.items),function(department,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
                        'departments__item',
                        {'departments__item--menu': department.submenu && department.submenu.type === 'menu'},
                        {'departments__item--hover': department === _vm.hoveredItem}
                    ],on:{"mouseenter":function($event){return _vm.onItemMouseEnter(department)}}},[_c('AppLink',{staticClass:"departments__item-link",attrs:{"to":department.url}},[_vm._v("\n                        "+_vm._s(department.title)+"\n                        "),(department.submenu)?_c('ArrowRoundedRight6x9Svg',{staticClass:"departments__item-arrow"}):_vm._e()],1)],1)}),0)])]),_vm._v(" "),_c('button',{staticClass:"departments__button",on:{"click":_vm.onClickButton}},[_c('Menu18x14Svg',{staticClass:"departments__button-icon"}),_vm._v("\n        Shop By Category\n        "),_c('ArrowRoundedDown9x6Svg',{staticClass:"departments__button-arrow"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }