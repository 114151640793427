

import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { RootState } from '~/store'
import { WishlistState } from '~/store/wishlist'
import { DropcartType } from '~/store/options'
import Sticky from '~/services/sticky'
import departments from '~/services/departments'

import Departments from '~/components/header/departments.vue'
import NavLinks from '~/components/header/nav-links.vue'
import AccountMenu from '~/components/header/account-menu.vue'
import Notifications from '~/components/header/notifications.vue'
import Indicator from '~/components/header/indicator.vue'
import Dropcart from '~/components/header/dropcart.vue'
import AppLink from '~/components/shared/app-link.vue'
import Search from '~/components/header/search.vue'
import SearchScope from '~/components/header/search-scope.vue'

import LogoSmallSvg from '~/svg/logo-small.svg'
import Heart20Svg from '~/svg/heart-20.svg'
import Cart20Svg from '~/svg/cart-20.svg'
import Person20Svg from '~/svg/person-20.svg'
import Search20Svg from '~/svg/search-20.svg'
import Cross20Svg from '~/svg/cross-20.svg'

import TrumpetSvg from '~/svg/trumpet.svg'
import BellSvg from '~/svg/bell.svg'
import WishlistSvg from '~/svg/wishlist.svg';

import notificationsApi from '~/api/notifications'
import { BIconHeart } from 'bootstrap-vue'

type Layout = 'default' | 'compact';

type StickyMode = 'pullToShow' | 'alwaysOnTop' | false

@Component({
    components: {
        SearchScope,
        Dropcart,
        Indicator,
        NavLinks,
        Departments,
        LogoSmallSvg,
        Heart20Svg,
        Cart20Svg,
        Person20Svg,
        AccountMenu,
        Notifications,
        AppLink,
        Search20Svg,
        Cross20Svg,
        Search,
        TrumpetSvg,
        BellSvg,
        WishlistSvg,
    },
    computed: {
        quantity: {
            get () {
                return this.$store.getters['cart/quantity']
            }
        },
        isAuthenticate: {
            get () {
                if (this.$store.getters['auth/userId']) {
                    return true
                }
                return false
            }
        }
    }
})
export default class NavPanel extends Vue {
    @Prop({ type: String, default: () => 'default' }) readonly layout!: Layout
    @Prop({ type: [String, Boolean], default: () => false }) readonly stickyMode!: StickyMode

    @State((state: RootState) => state.options.dropcartType) dropcartType!: DropcartType
    @State((state: RootState) => state.cart.items) items!: any
    @State((state: RootState) => state.wishlist) wishlist!: WishlistState

    @Ref() readonly departments: Departments | undefined
    @Ref() readonly nav: NavLinks | undefined
    @Ref() readonly searchIndicator: Indicator | undefined
    @Ref() readonly cartIndicator: Indicator | undefined
    @Ref() readonly accountIndicator: Indicator | undefined
    @Ref() readonly search: Search | undefined

    sticky: Sticky | null = null
    
    hasnew: Boolean = true
    newcount: number = 0
    notifications: any = []
    statudPtr: number = 0
    idlePtr: any = 0

    mounted () {
        const element = this.$el as HTMLElement

        this.sticky = new Sticky(element, {
            stuck: 'nav-panel--stuck',
            show: 'nav-panel--show'
        })

        this.sticky.on('stuck', () => {
            if (this.departments) {
                this.departments.setStickyState(true)
            }
        })
        this.sticky.on('fix', () => {
            if (this.departments) {
                this.departments.setStickyState(false)
            }
        })
        this.sticky.on('hide', () => {
            if (this.departments) {
                this.departments.closeMenu(true)
            }
            if (this.nav) {
                this.nav.closeSubmenu()
            }
            if (this.searchIndicator) {
                this.searchIndicator.close(true)
            }
            if (this.cartIndicator) {
                this.cartIndicator.close(true)
            }
            if (this.accountIndicator) {
                this.accountIndicator.close(true)
            }
        })

        this.sticky.start()
        this.sticky.calc(element, departments.element ? [departments.element] : [])

        if (this.$store.getters['auth/userId']) {
            if (process.browser) {
                this.newcount = Number(localStorage.getItem('lastcount'))
                if (isNaN(this.newcount)) {
                    this.newcount = 0
                }
            }

            this.loadNotificationData()
            this.loadNotificationCount()

            setInterval(() => {
                this.loadNotificationCount()
            }, 60000)
        }
    }

    beforeDestroy () {
        if (this.sticky) {
            this.sticky.destroy()
        }
    }

    onSetDepartmentsArea (area: Element) {
        const element = this.$el as Element

        if (this.sticky) {
            this.sticky.calc(element, area ? [area] : [])
        }
    }

    onCartClick () {
        if (this.$store.state.options.dropcartType === 'offcanvas') {
            this.$store.commit('offcanvasCart/open')
        }
    }
    
    loadNotificationCount() {
        notificationsApi.getstatus().then((response) => {
            if (response.data) {
                if (response.data.new > this.newcount) {
                    this.hasnew = true
                    this.newcount = response.data.new
                    // save to local storage, we are going to use this next page reload
                    if (process.browser) {
                        localStorage.setItem('lastcount', response.data.new)
                    }
                }
            }
        })
    }

    loadNotificationData() {
        if (this.hasnew) {
            notificationsApi.list({ page: { size: 1000 } }).then((response) => {
                if (response.data) {
                    this.notifications = response.data
                }
            })
            this.hasnew = false
        }
    }
}

