import api from './api'

export default {
    add(params) {
        return api.post('notifications', params)
    },
    getstatus() {
        return api.fetch('notifications/status')
    },
    list(params) {
        return api.fetch('notifications', params)
    },
    update(params) {
        return api.patch('notifications', params)
    },
    delete(id) {
        return api.remove('notifications', id)
    }
}