
import { Vue, Component, Prop } from "vue-property-decorator";

type Shape = "circle" | "rounded";

@Component({})
export default class SocialLinks extends Vue {
  @Prop({ type: String, default: () => "circle" }) readonly shape!: Shape;

  items = [
    {
      type: "rss",
      icon: "fas fa-rss",
      url: "",
    },
    {
      type: "youtube",
      icon: "fab fa-youtube",
      url: "",
    },
    {
      type: "facebook",
      icon: "fab fa-facebook-f",
      url: "",
    },
    {
      type: "twitter",
      icon: "fab fa-twitter",
      url: "",
    },
    {
      type: "instagram",
      icon: "fab fa-instagram",
      url: "",
    },
  ];
}
