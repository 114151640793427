

import { Vue, Component, Prop } from 'vue-property-decorator'
import AppLink from '~/components/shared/app-link.vue'

@Component({
    components: { AppLink },
    head (this: ErrorLayout) {
        return {
            title: this.statusCode === 404 ? '404 Page Not Found' : 'Something Went Wrong...'
        }
    }
})
export default class ErrorLayout extends Vue {
    @Prop({ type: Object, default: () => null }) readonly error!: {statusCode?: number; message?: string} | null

    get statusCode () {
        return (this.error && this.error.statusCode) || 500
    }

    get message () {
        return (this.error && this.error.message) || '<%= messages.client_error %>'
    }
}

