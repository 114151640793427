import sectionbannerApi from "~/api/sectionbanner";

function makeState () {
    return {
        sectionbannerId: null,
        sectionbanner: {},
        sectionbanners: {},
        options: {},
        filters: {},
        query: ''
    }
}

export const state = makeState

export const mutations = {
    init (state, payload) {
        Object.assign(state, makeState(), {
            sectionbannerId: payload.sectionbannerId,
            options: payload.options,
            filters: payload.filters
        })
    },
    SET_SECTIONBANNERS (state, sectionbanners) {
        state.sectionbanners = sectionbanners
    },
    SET_SECTIONBANNER (state, sectionbanner) {
        state.sectionbanner = sectionbanner
    }
}

export const getters = {
    fetchSectionBanners (state) {
        return state.sectionbanners
    },
    fetchSectionBanner (state) {
        return state.sectionbanner
    }
}

export const actions = {
    async init ({ dispatch, commit }, payload) {
        commit('init', payload)

        await Promise.all([ dispatch('fetchSectionBanner', payload.categoryId) ])
    },
    async fetchSectionBanners ({ commit }, params) {
        try {
            const { data } = await sectionbannerApi.list(params)
            if (data) {
                data.forEach((e) => { e.products = e.products?.data });

                commit('SET_SECTIONBANNERS', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    },
    async fetchSectionBanner ({ commit }, id) {
        try {
            const { data } = await sectionbannerApi.get(id)
            if (data) {
                commit('SET_SECTIONBANNER', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    }
}