import api from './api'

export default {
    add(cart_header) {
        return api.post('cart_header', cart_header)
    },
    async get(id) {
        const res = await api.fetch(`cart_header/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('cart_header', params)
        return res
    },
    update(cart_header) {
        return api.patch('cart_header', cart_header)
    },
    delete(id) {
        return api.remove('cart_header', id)
    },
    async getByUser() {
        const res = await api.fetch(`cart_header/getcartbyusers`)
        return res
    },
}