var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{class:[
        'megamenu__links',
        `megamenu__links--level--${_vm.level}`
    ]},_vm._l((_vm.links),function(link,index){return _c('li',{key:index,class:[
            'megamenu__item',
            {'megamenu__item--with-submenu': link.children}
        ]},[(link.name)?_c('AppLink',{attrs:{"to":link.url}},[_vm._v("\n            "+_vm._s(link.name)+"\n        ")]):_vm._e(),_vm._v(" "),(link.children)?_c('MegamenuLinks',{attrs:{"links":link.children,"level":_vm.level + 1}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }