import api from './api'

import CatalogTypeFilterBuilder from '~/filters/types'
import YearRangeFilterBuilder from '~/filters/year'

export default {
    add(params) {
        return api.post('catalogs', params)
    },
    async get(id) {
        const res = await api.fetch(`catalogs/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('catalogs', params)
        return res
    },
    update(params) {
        return api.patch('catalogs', params)
    },
    delete(id) {
        return api.remove('catalogs', id)
    },

    async GetCatalogList(options, filterValues) {
        const filters = [
            new CatalogTypeFilterBuilder('types', 'Catalog Types'),
            new YearRangeFilterBuilder('year', 'Publish Date')
        ]

        let items = await this.list({ 'page[size]': 1000, status: 'O' }).then(({ data }) => { return data })
        const attributes = items

        filters.forEach(filter => filter.makeItems(attributes, filterValues[ filter.slug ]))

        // Calculate items count for filter values.
        filters.forEach(filter => filter.calc())

        const page = options.page || 1
        const limit = options.limit || 12
        const sort = options.sort || 'default'
        const total = items.length
        const pages = Math.ceil(total / limit)
        const from = (page - 1) * limit + 1
        const to = Math.max(Math.min(page * limit, total), from)

        items = items.sort((a, b) => {
            if ([ 'name_asc', 'name_desc' ].includes(sort)) {
                if (a.name === b.name) {
                    return 0
                }

                return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1)
            }

            return 0
        })

        const start = (page - 1) * limit
        const end = start + limit

        items = items.slice(start, end)

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ page, limit, sort, total, pages, from, to, items, filters: filters.map(x => x.build()) })
            }, 350)
        })
    }
}