

import { Vue, Component } from 'vue-property-decorator'
import { Getter,State,Mutation } from 'vuex-class'
import { RootState } from '~/store'
import { HeaderLayout } from '~/store/options'
import Search from '~/components/header/search.vue'
import NavPanel from '~/components/header/nav-panel.vue'
import Topbar from '~/components/header/topbar.vue'
import AppLink from '~/components/shared/app-link.vue'
import LogoSvg from '~/svg/logo.svg'
import theme from '~/data/theme'
import Dropdown from '~/components/header/dropdown.vue'
import dataLanguages from '~/data/languages'
import { ILanguage } from '~/interfaces/language'
import dataShopCurrencies from '~/data/shopCurrencies'
import { ICurrency } from '~/interfaces/currency'

@Component({
    components: { AppLink, NavPanel, Search, Topbar, LogoSvg,Dropdown }
})
export default class Header extends Vue {
    theme = theme
    @Getter('locale/language') language!: ILanguage
    @State((state: RootState) => state.options.headerLayout) layout!: HeaderLayout
    @Mutation('currency/set') setCurrency!: (currency: ICurrency) => void
    @State((state: RootState) => state.currency.current) currency!: ICurrency

    currencies = dataShopCurrencies.map((currency) => {
        return {
            name: `${currency.symbol} ${currency.name}`,
            currency
        }
    })

    languages= dataLanguages.map((language) => {
        return {
            title: language.name,
            icon: language.icon,
            locale: language.locale
        }
    })

    setLanguage (locale: string) {
        const fullPath = this.$route.fullPath
        const re = new RegExp('^/(' + dataLanguages.map(x => x.locale).join('|') + ')(/|$)')
        const path = fullPath.replace(re, '/')

        this.$router.push(`/${locale}${path}`)
    }
}

