var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-number"},[_c('input',{class:[
            'form-control',
            'input-number__input',
            {
                'form-control-sm': _vm.size === 'sm',
                'form-control-lg': _vm.size === 'lg',
            }
        ],attrs:{"id":_vm.id,"step":_vm.step,"min":_vm.min,"max":_vm.max,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"number"},domProps:{"value":_vm.value},on:{"input":_vm.handleInput}}),_vm._v(" "),_c('div',{staticClass:"input-number__add",on:{"mousedown":_vm.handleAddMouseDown}}),_vm._v(" "),_c('div',{staticClass:"input-number__sub",on:{"mousedown":_vm.handleSubMouseDown}})])
}
var staticRenderFns = []

export { render, staticRenderFns }