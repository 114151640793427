import api from './api'

export default {
    add(cart_detail) {
        return api.post('cart_detail', cart_detail)
    },
    async get(id) {
        const res = await api.fetch(`cart_detail/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('cart_detail', params)
        return res
    },
    update(cart_detail) {
        return api.patch('cart_detail', cart_detail)
    },
    delete(id) {
        return api.remove('cart_detail', id)
    },

    addToCart(id, body) {
        return api.request({
            url: `/cart_details/${id}/addtocarts`,
            method: 'POST',
            body,
            type: 'cart_details'
        })
    },
    updateQuantity(id, body) {
        return api.request({
            url: `/cart_details/${id}/updatequantities`,
            method: 'POST',
            body,
            type: 'cart-detail-quantities'
        })
    },
    async getMiniCart(id) {
        const res = await api.fetch(`cart_details/${id}/minicarts`)
        return res
    },
    clearCart(id) {
        return api.request({
            url: `/cart_details/${id}/clearcarts`,
            method: 'DELETE',
        })
    },
    draftCart(id, body) {
        return api.request({
            url: `/cart_details/${id}/draftcarts`,
            method: 'POST',
            body,
            type: 'draftcarts'
        })
    },
    checkOut(id) {
        return api.request({
            url: `/cart_details/${id}/checkouts`,
            method: 'POST',
        })
    },
    loadCart(id) {
        return api.fetch(`cart_detail/${id}/loadcarts`)
    },
    resaveToCart(id) {
        return api.fetch(`cart_detail/${id}/resavetodrafts`)
    },
}