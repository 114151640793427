import api from './api'

export default {
    add(category) {
        return api.post('category', category)
    },
    async get(id) {
        const res = await api.fetch(`category/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('category', params)
        return res
    },
    update(category) {
        return api.patch('category', category)
    },
    delete(id) {
        return api.remove('category', id)
    }
}