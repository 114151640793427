var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        'menu',
        `menu--layout--${_vm.layout}`,
        {'menu--with-icons': _vm.withIcons}
    ],on:{"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"menu__submenus-container"},[_vm._l((_vm.items),function(item){return [(item.children && item.children.length > 0)?_c('div',{key:item.url,ref:"submenus",refInFor:true,staticClass:"menu__submenu"},[_c('Menu',{attrs:{"items":item.children}})],1):_vm._e()]})],2),_vm._v(" "),_c('ul',{staticClass:"menu__list"},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
                'menu__item',
                {'menu__item--hover': item === _vm.hoveredItem}
            ],on:{"mouseover":function($event){return _vm.onItemMouseOver(item)}}},[_c('div',{staticClass:"menu__item-submenu-offset"}),_vm._v(" "),(item.url)?_c('AppLink',{staticClass:"menu__item-link",attrs:{"to":item.url},nativeOn:{"click":function($event){return _vm.onItemClick(item)}}},[(item.icon)?_c('div',{staticClass:"menu__item-icon"},[_c('img',{attrs:{"src":_vm.$url.img(item.icon),"alt":""}})]):_vm._e(),_vm._v("\n                "+_vm._s(item.name)+"\n                "),(item.children && item.children.length > 0)?_c('ArrowRoundedRight6x9Svg',{staticClass:"menu__item-arrow"}):_vm._e()],1):_c('button',{staticClass:"menu__item-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.onItemClick(item)}}},[(item.icon)?_c('span',{staticClass:"menu__item-icon"},[_c('img',{attrs:{"src":_vm.$url.img(item.icon),"alt":""}})]):_vm._e(),_vm._v("\n                "+_vm._s(item.name)+"\n                "),(item.children && item.children.length > 0)?_c('ArrowRoundedRight6x9Svg',{staticClass:"menu__item-arrow"}):_vm._e()],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }