import api from './api'

export default {
    add(params) {
        return api.post('sectionbanner', params)
    },
    async get(id) {
        const res = await api.fetch(`sectionbanner/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('sectionbanner', params)
        return res
    },
    update(params) {
        return api.patch('sectionbanner', params)
    },
    delete(id) {
        return api.remove('sectionbanner', id)
    }
}