import headerbannerApi from "~/api/headerbanner";

function makeState () {
    return {
        headerbannerId: null,
        headerbanner: {},
        headerbanners: {},
        options: {},
        filters: {},
        query: ''
    }
}

export const state = makeState

export const mutations = {
    init (state, payload) {
        Object.assign(state, makeState(), {
            headerbannerId: payload.headerbannerId,
            options: payload.options,
            filters: payload.filters
        })
    },
    SET_HEADERBANNERS (state, headerbanners) {
        state.headerbanners = headerbanners
    },
    SET_HEADERBANNER (state, headerbanner) {
        state.headerbanner = headerbanner
    }
}

export const getters = {
    fetchHeaderBanners (state) {
        return state.headerbanners
    },
    fetchHeaderBanner (state) {
        return state.headerbanner
    }
}

export const actions = {
    async init ({ dispatch, commit }, payload) {
        commit('init', payload)

        await Promise.all([ dispatch('fetchHeaderBanner', payload.categoryId) ])
    },
    async fetchHeaderBanners ({ commit }, params) {
        try {
            const { data } = await headerbannerApi.list(params)
            if (data) {
                const bySection = data.filter((x) => { return x.section == params.section })
                commit('SET_HEADERBANNERS', bySection)
                return bySection
            }
        } catch (err) {
            return Promise.reject(err)
        }
    },
    async fetchHeaderBanner ({ commit }, id) {
        try {
            const { data } = await headerbannerApi.get(id)
            if (data) {
                commit('SET_HEADERBANNER', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    }
}