import UserAPI from '../api/user'

export const state = () => ({
    user: {
        id: '',
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        mobile: '',
        language: '',
        avatar_url: '',
        address: {
            street: '',
            city: '',
            zip_code: ''
        },
        country: '',
        social_login: '',
        authenticator_enabled: false,
        roles: null,
        active: true,
        mobile_verified: false,
        recovery_questions_set: false
    }
})

export const getters = {
    user(state) {
        return state.user
    }
}

export const mutations = {
    SET_USER(state, user) {
        state.user = user
    }
}

export const actions = {
    fetchUser({ commit }, id) {
        return UserAPI.get(id)
            .then(res => commit('SET_USER', res.data))
            .catch((err) => {
                commit('SET_USER', null)
                return Promise.reject(err)
            })
    },
    updateUser({ commit }, user) {
        return UserAPI.update(user)
            .then(res => commit('SET_USER', res.data))
            .catch((err) => { return Promise.reject(err) })
    },
    removeUser({ commit }, user) { commit('SET_USER', null) }
}
