import AbstractFilterBuilder from './abstract'

export default class YearRangeFilterBuilder extends AbstractFilterBuilder<any> {
    min: number = 0;
    max: number = 0;

    value: any = [ 0, 0 ];

    test(catalog: any): boolean {
        return true
    }

    async makeItems(product: any[], value?: string): Promise<void> {
    }

    // eslint-disable-next-line class-methods-use-this
    calc() { }

    build(): any {
        return {
            type: 'year',
            slug: this.slug,
            name: this.name,
            min: this.min,
            max: this.max,
            value: this.value
        }
    }
}
