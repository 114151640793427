import AbstractFilterBuilder from './abstract'

export default class CatalogTypeFilterBuilder extends AbstractFilterBuilder<any> {
    value: any = null;

    items: any[] = [];

    test() {
        return true
    }

    async makeItems(_: any[], value?: string): Promise<void> {
    }

    // eslint-disable-next-line class-methods-use-this
    calc(): void { }

    build(): any {
        return {
            type: 'catalogtype',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value
        }
    }
}
