<template>
    <div class="account-menu">
        <div class="account-menu__form-title">
            Notifications
        </div>
        <ul>
            <div v-for="item, key in items" :key="key" >
                {{ item.subject }}
            </div>
        </ul>
    </div>
</template>

<script>
import AppLink from '~/components/shared/app-link.vue'
import Cross12Svg from '~/svg/cross-12.svg'

export default {
    components: { AppLink, Cross12Svg },
    head: { title: 'Notification' },
    props: {
        items: {
            type: Array,
            default: {}
        }
    },
    data () {
        return {
        }
    },
    mounted () {
    },
    methods: {
    }
}

</script>
