var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"product__gallery"},[_c('div',{staticClass:"product-gallery"},[_c('div',{staticClass:"product-gallery__featured"},[(_vm.layout !== 'quickview')?_c('button',{staticClass:"product-gallery__zoom",attrs:{"type":"button"},on:{"click":_vm.handleZoomButtonClick}},[_c('ZoomIn24Svg')],1):_vm._e(),_vm._v(" "),_c('Carousel',{ref:"featured",attrs:{"options":_vm.featuredGalleryOptions}},_vm._l((_vm.images),function(image,index){return _c('CarouselSlide',{key:index},[_c('div',{staticClass:"product-image product-image--location--gallery"},[_c('a',{staticClass:"product-image__body",attrs:{"href":_vm.$url.image('products', image.productid, image.thumbnail),"target":"_blank"},on:{"click":function($event){return _vm.handleFeaturedClick($event, index)}}},[_c('img',{ref:"images",refInFor:true,staticClass:"product-image__img",attrs:{"src":_vm.$url.image('products', image.productid, image.thumbnail),"alt":image.id,"data-width":"700","data-height":"700"}})])])])}),1)],1),_vm._v(" "),_c('div',{staticClass:"product-gallery__carousel"},[_c('Carousel',{ref:"thumbnails",attrs:{"options":_vm.thumbnailOptions}},_vm._l((_vm.images),function(image,index){return _c('CarouselSlide',{key:index},[_c('a',{class:[
              'product-gallery__carousel-item product-image',
              {
                'product-gallery__carousel-item--active':
                  index === _vm.currentIndex,
              },
            ],on:{"click":function($event){return _vm.handleThumbnailClick(index)}}},[_c('span',{staticClass:"product-image__body"},[_c('img',{staticClass:"product-image__img product-gallery__carousel-image",attrs:{"src":_vm.$url.image('products', image.productid, image.thumbnail),"alt":""}})])])])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }