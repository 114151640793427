import { makeIdGenerator, nameToSlug } from '../utils'
import { ICategoryDef } from '../interfaces/categories'
import { IBaseCategory, IShopCategory } from '~/interfaces/category'

const getId = makeIdGenerator()

const categoriesDef: ICategoryDef[] = [
    {
        name: 'Marine Engines',
        slug: 'marineEngines',
        items: 2,
        children: [
            { name: 'Steyr 4-Cylinder', slug: 'steyr4Cylinder' },
            { name: 'Steyr 6-Cylinder', slug: 'steyr6Cylinder' },
            { name: 'Steyr IFG Power System', slug: 'steyrIFGPowerSystem' }
        ]
    },
    {
        name: 'Engine Hardware',
        slug: 'engineHardware',
        items: 3
    },
    {
        name: 'Power Supply',
        slug: 'powerSupply',
        items: 4
    },
    {
        name: 'Engine Controls',
        slug: 'engineControls',
        items: 4
    },
    {
        name: 'Steering',
        slug: 'steering',
        items: 4
    },
    {
        name: 'Navigation',
        slug: 'navigation',
        items: 4
    },
    {
        name: 'Pumps',
        slug: 'pumps',
        items: 4
    },
    {
        name: 'Air Conditioner',
        slug: 'airConditioner',
        items: 4
    },
    {
        name: 'Seats',
        slug: 'seats',
        items: 4
    },
    {
        name: 'Anchoring & Mooring',
        slug: 'anchorMooring',
        items: 4
    },
    {
        name: 'Sanitation',
        slug: 'sanitation',
        items: 4
    },
    {
        name: 'Maritime Safety',
        slug: 'maritimeSafety',
        items: 4
    },
    {
        name: 'Hardware Fittings',
        slug: 'hardwareFittings',
        items: 4
    }
]

type MakeFn<T extends IBaseCategory = IBaseCategory> = (def: ICategoryDef) => T;

function makeShopCategory (def: ICategoryDef): IShopCategory {
    return {
        type: 'shop',
        id: getId(),
        name: def.name,
        slug: def.slug || nameToSlug(def.name),
        image: def.image,
        items: def.items || 0,
        customFields: {},
        parent: undefined,
        children: [],
        data: [],
        parentid: 0
    }
}

function walkTree<T extends IBaseCategory = IBaseCategory> (makeFn: MakeFn<T>, defs: ICategoryDef[], parent?: T): [T[], T[]] {
    let list: T[] = []

    const tree = defs.map((def) => {
        const category: T = makeFn(def)

        const [childrenTree, childrenList] = walkTree(makeFn, def.children || [], category)

        category.parent = parent
        category.children = childrenTree
        list = [...list, category, ...childrenList]

        return category
    })

    return [tree, list]
}

export function prepareCategory<T extends IBaseCategory> (category: T, depth?: number): T {
    let children

    if (depth && depth > 0) {
        children = category.children && category.children.map(x => prepareCategory(x, depth - 1))
    }

    return JSON.parse(JSON.stringify({
        ...category,
        parent: category.parent ? prepareCategory(category.parent) : null,
        children
    }))
}

export const [categoriesTreeData, categoriesListData] = walkTree(makeShopCategory, categoriesDef)
