import AbstractFilterBuilder from './abstract'
import { ICategoryFilter, ICategoryFilterValue } from '~/interfaces/filter'
import { IProduct } from '~/interfaces/product'
import { IShopCategory } from '~/interfaces/category'
import categoryApi from '~/api/category'

export default class CategoryFilterBuilder extends AbstractFilterBuilder<ICategoryFilter> {
    value: ICategoryFilterValue = null;

    items: IShopCategory[] = [];

    test () {
        if (this.value === null) { return true }

        return true
    }

    async makeItems (_: IProduct[], value?: string): Promise<void> {
        const categories = await categoryApi.list({ 'page[size]': 1000 }).then(({ data }) => {
            if (data) {
                data.forEach(e => {
                    e.children = e.children?.data
                    if (e.parentid != 0) { e.parent = data.find((x) => { return +x.id == e.parentid}) }
                });
    
                return data
            }
        })

        this.value = value || null

        const categoryNew = categories.find((x) => x.id == value)

        if (categoryNew) {
            this.items = [categoryNew]
        } else {
            this.items = categories.filter((x) => { return x.parentid == 0 }).map(x => this.prepareCategory(x))
        }
    }

    // eslint-disable-next-line class-methods-use-this
    calc (): void {}

    build (): ICategoryFilter {
        return {
            type: 'category',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value
        }
    }

    prepareCategory (category: any, depth?: number): any {
        let children
    
        if (depth && depth > 0) {
            children = category.children && category.children.map(x => this.prepareCategory(x, depth - 1))
        }
    
        return JSON.parse(JSON.stringify({
            ...category,
            parent: category.parent ? this.prepareCategory(category.parent) : null,
            children
        }))
    }
}
