
import { Vue, Component, Prop } from "vue-property-decorator";
import { ILink } from "~/interfaces/menus/link";
import AppLink from "~/components/shared/app-link.vue";

@Component({
  components: { AppLink },
})
export default class FooterLinks extends Vue {
  @Prop({ type: String, default: () => "" }) readonly title!: string;
  @Prop({ type: Array, default: () => [] }) readonly links!: ILink[];
}
