import AuthAPI from '../api/auth'
import user from '../api/user'

export const state = () => ({
    userId: 0,
    userIdLoggedIn: 0,
    data: {}
})

export const getters = {
    isAuthenticated(state) {
        let id: Number = 0
        if (process.browser) {
            id = Number(localStorage.getItem('userId'))
        }
        return id ? true : false
    },
    userId(state) {
        return state.userId
    },
    getUser(state) {
        return state.data
    }
}

export const mutations = {
    AUTHENTICATING_SUCCESS(state, payload) {
        AuthAPI.setToken(payload.token)
        state.userId = payload.user_id
        localStorage.setItem('userId', payload.user_id)
    },
    AUTHENTICATING_ERROR(state) {
        state.userId = 0
        AuthAPI.removeToken()
        if (process.browser) {
            localStorage.clear()
        }
    },
    SET_USER(state, payload) {
        state.data = payload
    }
}

export const actions = {
    login({ commit }, payload) {
        return AuthAPI.login(payload).then(async (res) => {
            commit('AUTHENTICATING_SUCCESS', res.data)
            if (res.data) {
                const { data } = await user.get(res.data.user_id)
                commit('SET_USER', data)
            }
        }).catch((err) => {
            commit('AUTHENTICATING_ERROR')
            return Promise.reject(err)
        })
    },
    logout({ commit }) {
        return AuthAPI.logout().then(() => {
            commit('AUTHENTICATING_ERROR')
        }).catch((err) => {
            return Promise.reject(err)
        })
    },
    socialCallback({ commit }, payload) {
        return AuthAPI.socialCallback(payload).then((res) => {
            commit('AUTHENTICATING_SUCCESS', res.data)
        }).catch((err) => {
            commit('AUTHENTICATING_ERROR')
            return Promise.reject(err)
        })
    },
    removeSession({ commit }) {
        commit('AUTHENTICATING_ERROR')
    },
    refreshToken({ commit }) {
        return AuthAPI.refreshToken().then((res) => {
            commit('AUTHENTICATING_SUCCESS', res.data)
        }).catch((err) => {
            commit('AUTHENTICATING_ERROR')
            return Promise.reject(err)
        })
    }
}