import productApi from "~/api/product";

function makeState () {
    return {
        headerbannerId: null,
        product: {},
        products: {},
        options: {},
        filters: {},
        query: ''
    }
}

export const state = makeState

export const mutations = {
    init (state, payload) {
        Object.assign(state, makeState(), {
            headerbannerId: payload.headerbannerId,
            options: payload.options,
            filters: payload.filters
        })
    },
    SET_PRODUCTS (state, products) {
        state.products = products
    },
    SET_PRODUCT (state, product) {
        state.product = product
    }
}

export const getters = {
    fetchProducts (state) {
        return state.products
    },
    fetchProduct (state) {
        return state.product
    }
}

export const actions = {
    async init ({ dispatch, commit }, payload) {
        commit('init', payload)

        await Promise.all([ dispatch('fetchProduct', payload.categoryId) ])
    },
    async fetchProducts ({ commit }, params) {
        try {
            const { data } = await productApi.list(params)
            if (data) {
                commit('SET_PRODUCTS', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    },
    async fetchProduct ({ commit }, id) {
        try {
            const { data } = await productApi.get(id)
            if (data) {
                commit('SET_PRODUCT', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    }
}