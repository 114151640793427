

import { Vue, Component } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { RootState } from '~/store'
import { DropcartType } from '~/store/options'
import Footer from '~/components/footer/footer.vue'
import Header from '~/components/header/header.vue'
import MobileHeader from '~/components/mobile/mobile-header.vue'
import Dropcart from '~/components/header/dropcart.vue'
import MobileMenu from '~/components/mobile/mobile-menu.vue'
import Quickview from '~/components/shared/quickview.vue'

@Component({
    components: {
        MobileMenu,
        Dropcart,
        MobileHeader,
        Header,
        Footer,
        Quickview
    },
    head (this: Layout) {
        return {
            bodyAttrs: {
                class: this.bodyClasses
            }
        }
    }
})
export default class Layout extends Vue {
    @State((state: RootState) => state.options.dropcartType) dropcartType!: DropcartType

    bodyClasses = ['disable-transitions']

    async mounted () {
        await this.$nextTick()

        this.bodyClasses = []
    }
}

